import React from "react";
import { useHistory } from "react-router-dom";
import delay from "lodash.delay";
import Modal from "./Modal";
import cross from "../assets/cross.svg";
import { Link } from "react-router-dom";

import { Header2 } from "./Text";

const About = () => {
  const history = useHistory();

  const delayClose = () => delay(onClose, 10);
  const onClose = () => {
    history.push("/");
    document.body.style.overflow = "unset";
  };

  return (
    <Modal open={true} onClose={delayClose}>
      <div className="bg-gray-400 max-h-full overflow-scroll h-screen text-gray-50 absolute w-full lg:w-3/4 xl:w-3/6  ">
        <div className="sticky bg-gradient-to-b from-gray-400 h-huger px-lg lg:px-xl w-full flex place-items-center justify-between">
          <div></div>
          <div
            onClick={() => delayClose()}
            className="hover:bg-gray-250 hover:bg-opacity-20 transition-colors p-sm cursor-pointer flex place-items-center rounded"
          >
            <img src={cross} />
          </div>
        </div>
        <div className="px-lg lg:px-huger pt-huge max-w-4xl -mt-huge">
          <Header2 className="mb-lg">About the project</Header2>
          <div className="font-normal font-serif text-base mb-xxl">
            <p>
              Beyond Translation is an exploration of esoteric concepts hidden
              in languages and cultures across the globe.
            </p>
            <p>
              There are many great multilingual dictionaries out there, but
              learning languages is not only about learning different
              expressions of what you already know, but learning another way to
              think. Our goal is to be a shortcut to the most differentiated
              ideas inside languages.
            </p>
            <p>
              <i>
                “You can know the name of a bird in all the languages of the
                world, but when you&apos;re finished, you&apos;ll know
                absolutely nothing whatever about the bird...”
              </i>
              <br />
              —Richard P. Feynman
            </p>
            <p>
              If that sounds interesting to you, join our community to discuss,
              research and curate with us.
            </p>
          </div>
          <p className="text-base">
            <Link
              className="text-salmon-400 hover:text-salmon-200 font-semibold"
              to={"/submit"}
            >
              Submit
            </Link>{" "}
            a word,
            <a
              className="text-salmon-400 hover:text-salmon-200 font-semibold"
              href="mailto:rob.h.mcmackin@gmail.com"
            >
              {" "}
              Email us
            </a>{" "}
            or{" "}
            <a
              className="text-salmon-400 hover:text-salmon-200 font-semibold"
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/byd_translation"
            >
              Follow us on Twitter
            </a>
            .
          </p>
          {/* <div className="bg-gray-150 w-full my-xxl" style={{ height: 2 }} />
          <p className="leading-loose  text-16 text-gray-100 rounded-md font-sans">
            <span className="font-semibold">Made by</span> <br />
            Rob McMackin
            <br />
            <br />
            <span className="font-semibold">Thanks to</span>
            <br />
            Jonathan Byrne & Matthew Ruddy — Dev support <br />
            Gillian Heinsohn — Illustration <br />
            Guillaume Thierry & Richard Harris — Concept feedback
            <br />
            <br />
            <span className="font-semibold">Language support</span>
            <br />
            🇮🇩 Colton Getter <br />
            🇷🇺 Anna Ivanova <br />
            🇵🇹 Alberto Queiroz <br />
            🇲🇲 Aye Myat Mon <br />
            🇰🇷 Kevin Guan <br />
            🇰🇷 Heeyoung Won <br />
            🇰🇷 Hyewon Won <br />
            🇫🇷 Amine Adrissi <br />
            <br />
          </p>
      */}
        </div>
      </div>
    </Modal>
  );
};

About.propTypes = {};

export default About;
