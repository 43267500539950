import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import progress from "../assets/progress.svg";

const TermTitle = ({ term, loaded, termTitleHeight }) => {
  // const [value, updateValue] = React.useState(0);
  // React.useEffect(() => {
  //   const interval = setInterval(()=>{
  //     updateValue(oldValue => {
  //       const newValue = oldValue + 10;

  //       if(newValue === )
  //     })
  //   })
  // })
  const isLongTerm = term.length > 15;
  return (
    <div className={classNames("w-full flex")}>
      {/* Header: Progress term */}
      <div
        style={{ height: termTitleHeight }}
        className="mt-xxxl absolute z-10 w-full text-center"
      >
        <div
          className={classNames(
            "text-xl text-gray-50 font-semibold tracking-tighter inline bg-load break-all leading-none",
            isLongTerm ? "lg:text-xl" : "lg:text-xxl"
          )}
          style={{
            backgroundImage: `url(${progress})`,
            backgroundRepeat: "no-repeat",
            transition: "background-size 1s cubic-bezier(0.19, 1, 0.22, 1)",
            color: "#fffff",
            textAlign: "center",
            WebkitTextFillColor: "transparent",
            WebkitBackgroundClip: "text",
          }}
        >
          {term}
        </div>
      </div>
      {/* Header: Background term */}
      <div
        style={{ height: termTitleHeight }}
        className="mt-xxxl z-0 absolute w-full text-center"
      >
        <h1
          className={classNames(
            "text-xl transition text-gray-50 font-semibold tracking-tighter inline break-all leading-none",
            isLongTerm ? "lg:text-xl" : "lg:text-xxl"
          )}
        >
          {term}
        </h1>
      </div>
      <div className="pl-xxl pr-xxxl max-h-full overflow-y-scroll"></div>
    </div>
  );
};

TermTitle.propTypes = {
  term: PropTypes.string,
  loaded: PropTypes.bool,
  termTitleHeight: PropTypes.number,
};

TermTitle.defaultProps = {};

export default TermTitle;
