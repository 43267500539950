import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { motion } from "framer-motion/dist/framer-motion";

import { Transition } from "@headlessui/react";
import speechBubbleIconPath from "../assets/term.svg";
import AppContext from "./AppContext";
import getEmojiFlag from "../helpers/flagemoji";

import Spinner from "./Spinner";
import { Callout } from "./Text";

const fadeIn = {
  hidden: { opacity: 0, y: -15 },
  show: {
    opacity: 1,
    y: 0,
  },
  exit: { opacity: 0, y: -15 },
};

const CountryPopover = ({ x, y, country }) => {
  const { id, name, flag } = country;
  // const [terms, setTerms] = useState([]);
  // useEffect(() => {
  //   (async () => setTerms(await getTerms(country.termIds)))();
  // }, [country]);
  const context = useContext(AppContext);
  const allTerms = context.terms;
  const filteredTerms = allTerms.filter((term) => term.country === name);
  // make sure there's not too many
  const terms =
    filteredTerms.length > 2 ? filteredTerms.slice(0, 3) : filteredTerms;

  const empty = (
    <div className="active:bg-salmon-200 hover:bg-salmon-100  transition-colors place-items-center cursor-pointer flex p-lg">
      <Spinner className="mr-sm" />
      <span className="mr-md font-semibold text-base">Loading</span>
      {/* <span className="mr-md text-base">အားနာမ</span> */}
    </div>
  );

  const links = terms.map((term) => (
    <Link to={`/${name}/${term.name}`} key={term.id}>
      <div className="active:bg-salmon-200 hover:bg-salmon-100  transition-colors cursor-pointer flex p-lg">
        <img
          src={speechBubbleIconPath}
          className="mr-md ml-xxsm "
          alt="term-icon"
        />
        <span className="mr-md font-semibold mt-xxsm ml-xxsm text-base">
          {term.name}
        </span>
      </div>
    </Link>
  ));

  return (
    <motion.div
      variants={fadeIn}
      transition={{
        y: { type: "spring", bounce: 0.4, duration: 1.7 },
        duration: 0.4,
      }}
      animate="show"
      exit="exit"
      initial="hidden"
      className="w-full flex mb-1"
    >
      <div
        className="absolute text-brown-200 transform -translate-x-2/4 -translate-y-full w-massive"
        style={{ top: y - 36, left: x }}
      >
        <div className="bg-salmon-200">
          <div className="bg-salmon-100 place-items-center p-md flex">
            <div className="text-md ml-xsm mr-md mt-xsm">
              {getEmojiFlag(name)}
            </div>
            <Callout className="block mt-xxsm leading-none">{name}</Callout>
          </div>
          {links.length > 0 ? links : empty}
          <div className="-z-10 absolute bottom-0 left-1/2 w-md h-md transform rotate-45 -translate-x-1/2 translate-y-1/2 bg-salmon-200" />
        </div>
      </div>
    </motion.div>
  );
};

CountryPopover.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  country: PropTypes.shape(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      flag: PropTypes.shape({
        id: PropTypes.string,
        url: PropTypes.string,
      }),
      lat: PropTypes.number,
      lng: PropTypes.number,
      termIds: PropTypes.arrayOf(PropTypes.number),
    })
  ),
};

CountryPopover.defaultProps = {
  country: {
    name: null,
    flag: null,
  },
};

export default CountryPopover;
