import React from "react";
import PropTypes from "prop-types";

import TermCard from "./TermCard";

import Spinner from "./Spinner";
import { Header2 } from "./Text";

const FeaturedTerms = ({ terms }) => {
  const termsLoaded = terms.length;
  const termList = termsLoaded ? terms : [1, 2, 3];

  return (
    <>
      <Header2>Featured words</Header2>
      <div className="grid grid-cols-12 gap-xxl">
        {termList.map((term) => {
          return (
            <div
              style={{ height: termsLoaded ? "auto" : 581 }}
              key={term.name}
              className="col-span-12 lg:col-span-4 mt-lg flex content-center w-full h-full relative transition-colors bg-gray-200 rounded-md"
            >
              {termsLoaded ? (
                <TermCard term={term} />
              ) : (
                <>
                  <div className="font-normal font-serif text-sm text-gray-200 mt-sm">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  </div>
                  {/* have text, so it pads out, don't know why that happens */}
                  <div className="absolute w-full h-full">
                    <div className="flex place-items-center h-full w-full justify-center">
                      <Spinner color="#E9E7E7" />
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

FeaturedTerms.propTypes = {
  countries: PropTypes.array,
  terms: PropTypes.array,
};

export default FeaturedTerms;
