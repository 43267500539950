import base from "./airtable";

const formatTerm = (term) => ({
  id: term.id,
  name: term.get("Name"),
  country: term.get("Country"),
  exampleIds: term.get("Examples"),
  meaning: term.get("Meaning"),
  cover: term.get("Cover"),
  native: term.get("Native"),
  country: term.get("Country"),
  illustrator: term.get("Illustrator"),
  featured: term.get("Featured"),
  intro: term.get("Intro"),
});

export const getTerms = async (ids) => {
  try {
    let terms;
    if (ids) {
      terms = await Promise.all(ids.map((id) => getTerm(id)));
      return terms;
    } else {
      terms = await base("Terms").select({ view: "Main", pageSize: 100 }).all();
    }

    return terms.map(formatTerm);
  } catch (err) {
    return null;
  }
};

export const getTerm = async (id) => {
  try {
    const term = await base("Terms").find(id);

    return formatTerm(term);
  } catch (err) {
    console.log(err);
  }
};
