import base from "./airtable";

export const suggestEdit = async (subscriber) => {
  try {
    const records = await base("Edits").create([
      {
        fields: {
          Suggestion: subscriber.Edit,
          Term: [subscriber.Term],
        },
      },
    ]);
  } catch (err) {
    console.log(err);
  }
};
