import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Button = ({ children, className, primary, ghost, to, ...props }) => {
  let classes =
    "px-md pb-sm pt-10 text-base text-gray-50 font-medium flex rounded-md no-wrap transition cursor-pointer active:bg-gray-50 hover:bg-gray-200";

  if (primary) {
    classes = [
      className,
      classes,
      "bg-salmon-300 hover:bg-salmon-200 active:bg-gray-50 font-semibold text-gray-300",
    ].join(" ");
  } else if (ghost) {
    classes = [
      className,
      classes,
      "bg-none border border-gray-50 text-gray-50",
    ].join(" ");
  } else {
    classes = [className, classes, "bg-none"].join(" ");
  }

  if (!!to) {
    return (
      <Link to={to} className={classes} {...props}>
        {children}
      </Link>
    );
  }

  return (
    <a {...props} className={classes}>
      {children}
    </a>
  );
};

Button.propTypes = {
  children: PropTypes.string,
  primary: PropTypes.bool,
  link: PropTypes.string,
  ghost: PropTypes.bool,
  className: PropTypes.string,
  to: PropTypes.string,
};

export default Button;
