import React, { useEffect, useState, useContext } from "react";
import { tokenRequest, getCurrentUser } from "../services/discord";
import { getUser, registerUser } from "../services/users";
import { useLocation, useHistory } from "react-router-dom";
import AppContext from "./AppContext";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Login = () => {
  let query = useQuery();
  const code = query.get("code");

  let history = useHistory();

  const { loggedIn, setLoggedIn } = useContext(AppContext);
  const { user, setUser } = useContext(AppContext);

  const [token, setToken] = useState(null);
  const [discordUser, setDiscordUser] = useState(null);
  const [databaseUser, setDatabaseUser] = useState(null);

  // request log in token
  useEffect(async () => {
    if (!token) {
      const accessToken = await tokenRequest(code);
      setToken(accessToken);
    }
  }, []);

  // fetch user from discord
  useEffect(async () => {
    let user;
    if (token) {
      user = await getCurrentUser(token);
    }
    setDiscordUser(user);
  }, [token]);

  // check if user in airtable, if not, register
  useEffect(async () => {
    if (discordUser) {
      const dbuser = await getUser(discordUser.id);
      setDatabaseUser(dbuser);
      if (databaseUser) {
      } else {
        registerUser(discordUser);
      }
    }
  }, [discordUser]);

  // log user in with DB values
  useEffect(async () => {
    setLoggedIn(true);
    setUser(databaseUser);
    history.push("/");
  }, [databaseUser]);
  // set store to logged in + add user info

  return (
    <div className="absolute inset-0 bg-black bg-opacity-80 z-10 overflow-hidden flex place-items-center place-content-center ">
      <div className="absolute bg-gray-400  w-4/12 h-4/12 text-gray-50 p-huge">
        Logging you in...
      </div>
    </div>
  );
};
export default Login;
