import base from "./airtable";

const formatUser = (user) => ({
  id: user.id,
  name: user.get("Name"),
  avatar: user.get("Avatar"),
  email: user.get("Email"),
  discordId: user.get("DiscordId"),
  languages: user.get("Languages"),
});

export const getUsers = async () => {
  try {
    const users = await base("Users")
      .select({ maxRecords: 15, view: "Main" })
      .firstPage();
    return users.map(formatUser);
  } catch (err) {
    console.log(err);
  }
};

export const getUser = async (id) => {
  try {
    const user = await base("Users")
      .select({
        filterByFormula: `{DiscordId}=${id}`,
        maxRecords: 100,
        view: "Main",
      })
      .firstPage();
    return formatUser(user[0]);
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const registerUser = async (user) => {
  try {
    const users = await base("Users").create([
      {
        fields: {
          Name: user.username,
          Email: user.email,
          Avatar: user.avatar,
          Token: user.token,
          DiscordId: user.id,
        },
      },
    ]);
  } catch (err) {
    console.log(err);
  }
};
