import React from "react";
export default function Twitter() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7614 3.2887C15.2047 3.53536 14.6067 3.70203 13.978 3.77736C14.6194 3.39336 15.1114 2.78403 15.3434 2.0587C14.7434 2.4147 14.0787 2.67336 13.3714 2.81203C12.8047 2.20936 11.998 1.83203 11.1047 1.83203C9.39002 1.83203 7.99936 3.2227 7.99936 4.9387C7.99936 5.18136 8.02736 5.41736 8.07936 5.64536C5.49736 5.51536 3.21002 4.2787 1.67802 2.40003C1.41136 2.86003 1.25802 3.39336 1.25802 3.96136C1.25802 5.0387 1.80669 5.99003 2.63936 6.5467C2.13002 6.53003 1.65136 6.3907 1.23269 6.15803V6.19803C1.23269 7.7027 2.30269 8.95803 3.72402 9.24337C3.46269 9.31403 3.18869 9.35136 2.90602 9.35136C2.70602 9.35136 2.51069 9.3327 2.32136 9.2967C2.71669 10.53 3.86336 11.4287 5.22269 11.4527C4.15936 12.286 2.82002 12.7827 1.36536 12.7827C1.11469 12.7827 0.867357 12.768 0.624023 12.7394C1.99869 13.6214 3.63069 14.1347 5.38402 14.1347C11.0974 14.1347 14.2207 9.4027 14.2207 5.2987C14.2207 5.16536 14.2174 5.0307 14.2114 4.89736C14.818 4.4587 15.3447 3.9127 15.76 3.2907L15.7614 3.2887Z"
        fill="currentColor"
      />
    </svg>
  );
}
