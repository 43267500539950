import React, { useEffect, useState, useContext, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import delay from "lodash.delay";

import { Header2, Header3 } from "./Text";
import Modal from "./Modal";

import TermTitle from "./TermTitle";
import SuggestEdit from "./SuggestEdit";
import useTitle from "../hooks/useTitle";
import Button from "./Button";
import AppContext from "./AppContext";
import ReactMarkdown from "react-markdown";
import classNames from "classnames";

import pen from "../assets/pen.svg";
import cross from "../assets/cross-small.svg";
import Speaker from "../assets/speaker.svg";

const TRANSITION_DURATION = 10;
const termTitleHeight = 154;

const TermDetails = () => {
  const history = useHistory();
  const { countryName, termName } = useParams();
  const [title, setTitle] = useTitle();

  const [completed, setCompleted] = useState([]);
  const [suggestingEdit, setSuggestingEdit] = useState(false);

  const context = useContext(AppContext);
  const terms = context.terms;
  const term = terms.filter((term) => term.name === termName)[0];

  const termIndex = terms.indexOf(term);

  const prevTerm =
    termIndex === 0 ? terms[terms.length - 1] : terms[termIndex - 1];
  const nextTerm =
    termIndex === terms.length - 1 ? terms[0] : terms[termIndex + 1];

  const myRef = useRef(null);

  useEffect(async () => {
    if (terms.length) {
      myRef.current.scrollIntoView({ block: "end" });

      setTitle(term.name);
    }
  }, [terms, termName]);
  if (!term) {
    return null;
  }

  const onClose = () => {
    history.push(`/${countryName}`);
    document.body.style.overflow = "unset";
  };

  const delayClose = () => delay(onClose, TRANSITION_DURATION);

  const { name, meaning, cover, country, native, illustrator } = term;
  return (
    <Modal open={true} onClose={delayClose}>
      <div className="bg-gray-300 overflow-scroll  h-full text-gray-50 absolute w-full lg:w-auto left-1/2 transform -translate-x-1/2 rounded-md md:max-h-full xl:max-h-full xxl:mt-huge xxl:max-h-4/5">
        <div className="sticky   bg-gradient-to-b from-gray-400 h-huger px-lg lg:px-xl w-full flex place-items-center justify-between">
          <div className="flex text-base text-gray-100 font-normal">
            {country} | {name}
            {native && (
              <span className="text-gray-100 ml-xsm font-normal">
                [{native}]
              </span>
            )}
          </div>
          <div className="flex">
            <a
              target="_blank"
              className="p-sm block hover:bg-gray-250 transition-colors mr-md rounded"
              rel="noreferrer"
              href={`https://forvo.com/search/${
                !!term.native ? term.native : term.name
              }`}
            >
              <img src={Speaker} />
            </a>
            <div
              onClick={() => delayClose()}
              className="hover:bg-gray-250 hover:bg-opacity-80 transition-colors p-sm cursor-pointer flex place-items-center rounded"
            >
              <img src={cross} />
            </div>
          </div>
        </div>
        <div ref={myRef} />
        {cover && (
          <div
            className="-mt-huger"
            style={{
              backgroundImage: `url(${cover})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              height: 360,
              width: "100%",
            }}
          />
        )}
        {/* Header */}
        <TermTitle
          term={name}
          termTitleHeight={termTitleHeight}
          loaded={meaning}
        />
        {/* Body */}
        <div className="w-full min-h-full flex flex-col place-items-center mt-md">
          {meaning ? (
            <div
              style={{ marginTop: termTitleHeight }}
              className="max-h-full max-w-4xl mb-huge px-lg  lg:px-huger"
            >
              <div
                className={classNames(
                  "article-body font-normal font-serif text-sm text-gray-50 lg:min-w-144",
                  meaning.length > 200 && "drop-cap"
                )}
              >
                {/* Meaning */}
                <ReactMarkdown
                  components={{
                    h2: function render(children) {
                      return (
                        <div className="flex align-middle w-full mb-md mt-huge items-center">
                          <Header2 className="text-gray-75 whitespace-nowrap mr-xl">
                            {children && children.children[0]}
                          </Header2>
                          <div
                            className="bg-salmon-400 w-full"
                            style={{ height: 1 }}
                          />
                        </div>
                      );
                    },
                    h3: function render(children) {
                      return (
                        <Header3 className="text-gray-75 whitespace-nowrap mt-xxxl mb-sm">
                          {children.children[0]}
                        </Header3>
                      );
                    },
                  }}
                >
                  {meaning}
                </ReactMarkdown>
              </div>
              <div className="flex flex-col lg:flex-row justify-between place-items-center mt-xl pt-xl">
                <div onClick={() => setSuggestingEdit(true)}>
                  {!suggestingEdit && (
                    <Button>
                      <img className="mr-sm mb-xsm" src={pen} />
                      Suggest an edit
                    </Button>
                  )}
                </div>
                <div>
                  {cover && illustrator && !suggestingEdit && (
                    <div className=" mt-xl lg:mt-auto rounded text-base flex justify-center">
                      <div className="py-xsm text-gray-100 px-md bg-black inline-block rounded-lg">
                        Cover — {illustrator}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div>{suggestingEdit && <SuggestEdit term={term} />}</div>
            </div>
          ) : (
            <h2 className="text-lg text-gray-50 font-semibold ml-xl flex-1"></h2>
          )}
        </div>
        <div className="bottom-0 left-0 w-full sticky bg-gray-300 pb-xl px-lg lg:px-huger">
          <div className="bg-gray-150 mt-xxxl w-full" style={{ height: 1 }} />
          <div className="flex justify-between place-items-center pt-xl">
            <Button ghost to={`/${prevTerm.country}/${prevTerm.name}`}>
              Prev
            </Button>
            <Button ghost to={`/${nextTerm.country}/${nextTerm.name}`}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

TermDetails.propTypes = {};

TermDetails.defaultProps = {};

export default TermDetails;
