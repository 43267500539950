import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as THREE from "three";
import Globe from "react-globe.gl";

import CountryPopover from "./CountryPopover";

import { Callout } from "./Text";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import earthImage from "../assets/earth-dark.jpg";

const WorldMap = ({
  width,
  height,
  countries,
  selectedCountry,
  onCountrySelect,
}) => {
  const globeEl = useRef();
  const [timestamp, setTimestamp] = useState(null);

  const step = (t) => {
    setTimestamp(t);
    requestAnimationFrame(step);
  };

  useEffect(() => {
    !!selectedCountry &&
      globeEl.current.pointOfView(
        {
          lat: selectedCountry.lat,
          lng: selectedCountry.lng,
        },
        300
      );
  }, [selectedCountry]);

  useEffect(() => {
    globeEl.current.controls().enableZoom = false;
    globeEl.current.controls().autoRotate = true;
    globeEl.current.controls().autoRotateSpeed = -0.4;
    globeEl.current.pointOfView({ altitude: 1.5 });

    requestAnimationFrame(step);
  }, []);

  const onGlobeClick = () => onCountrySelect(null);
  const onPointClick = (point) => onCountrySelect(point.name);

  const data = countries;

  const { x = 0, y = 0 } =
    !!selectedCountry &&
    globeEl.current.getScreenCoords(selectedCountry.lat, selectedCountry.lng);

  return (
    <div
      className="relative bg-gray-200  rounded-full"
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <div className="absolute cursor-grabbable inset-0">
        <Globe
          ref={globeEl}
          animateIn={true}
          backgroundColor="#00000000"
          globeImageUrl={earthImage}
          height={height}
          onGlobeClick={onGlobeClick}
          customLayerData={data}
          customLayerLabel={(d) => {
            <Callout className="absolute" style={{ left: d.lat, top: d.lng }}>
              {d.label}
            </Callout>;
          }}
          onCustomLayerClick={onPointClick}
          customThreeObject={(d) =>
            new THREE.Mesh(
              new THREE.TetrahedronBufferGeometry(4),
              new THREE.MeshPhongMaterial({ color: 0xf0a084 })
            )
          }
          onCustomLayerHover={function hoverCube(obj, prevObj) {
            if (obj !== null && obj.__threeObj !== undefined) {
              (function enlargeCube() {
                obj.__threeObj.scale.x += 0.05;
                obj.__threeObj.scale.y += 0.05;
                obj.__threeObj.scale.z += 0.05;
                obj.color = "#ffffff";
                if (obj.__threeObj.scale.x < 1.4) {
                  requestAnimationFrame(enlargeCube);
                }
              })();
            } else if (prevObj !== null) {
              (function shrinkCube() {
                prevObj.__threeObj.scale.x -= 0.02;
                prevObj.__threeObj.scale.y -= 0.02;
                prevObj.__threeObj.scale.z -= 0.02;
                if (prevObj.__threeObj.scale.x > 1) {
                  requestAnimationFrame(shrinkCube);
                }
              })();
            }
          }}
          customThreeObjectUpdate={(obj, d) => {
            Object.assign(
              obj.position,
              globeEl.current.getCoords(d.lat, d.lng, 0.05)
            );
          }}
          showAtmosphere={false}
          width={width}
        />
      </div>
      <AnimatePresence initial={true}>
        {!!selectedCountry && (
          <CountryPopover x={x} y={y} country={selectedCountry} />
        )}
      </AnimatePresence>
    </div>
  );
};

WorldMap.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  countries: PropTypes.arrayOf(PropTypes.object),
  selectedCountry: PropTypes.object,
  onCountrySelect: PropTypes.func,
};

WorldMap.defaultProps = {};

export default WorldMap;
