import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Spinner = ({ className, color }) => {
  return (
    <svg
      className={classNames("spinner-rotating", className)}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15"
        stroke={color ? color : "#5D4D4D"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

Spinner.defaultProps = {};

export default Spinner;
