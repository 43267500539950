import fetch from "node-fetch";
import btoa from "btoa";
import React, { useEffect, useState } from "react";

export const tokenRequest = async (code) => {
  try {
    const creds = btoa(
      `${process.env.REACT_APP_CLIENT_ID}:${process.env.REACT_APP_CLIENT_SECRET}`
    );
    const data = new URLSearchParams({
      grant_type: "authorization_code",
      code: code,
      redirect_uri: "http://localhost:3000/login",
    });
    const headers = {
      Authorization: `Basic ${creds}`,
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const response = await fetch("https://discord.com/api/v8/oauth2/token", {
      method: "POST",
      body: data,
      headers: headers,
    });

    const json = await response.json();
    console.log("json", json);
    return json.access_token;
  } catch (err) {
    console.log(err);
  }
};

export const getCurrentUser = async (token) => {
  try {
    const response = await fetch("https://discord.com/api/users/@me", {
      method: "GET",
      // scope: "identify email",
      "Content-Type": "application/x-www-form-urlencoded",
      // "Access-Control-Allow-Headers": "Authorization, Content-Type",
      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Methods": "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const user = await response.json();
    return user;
  } catch (err) {
    console.log(err);
  }
};
