import base from "./airtable";

export const submitTerm = async (submission) => {
  try {
    const records = await base("Submissions").create([
      {
        fields: {
          Term: submission.term,
          Origin: submission.origin,
          Meaning: submission.meaning,
          Name: submission.contributorName,
          Email: submission.contributorEmail,
        },
      },
    ]);
  } catch (err) {
    console.log(err);
  }
};
