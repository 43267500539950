import { useState, useEffect } from "react";

const APP_TITLE = "Beyond Translation";

const useTitle = (t) => {
  const [title, setTitle] = useState(t);

  useEffect(() => {
    document.title = title ? `${title} | ${APP_TITLE}` : APP_TITLE;
  }, [title]);

  return [title, setTitle];
};

export default useTitle;
