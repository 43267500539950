import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Input = React.forwardRef((props, ref) => (
  <div className={classNames("", props.className)}>
    {props.label && (
      <label
        className="font-normal text-gray-75 block text-sm mb-md "
        htmlFor={props.name}
      >
        {props.label}{" "}
        {props.optional && <span className="text-gray-150">Optional</span>}
      </label>
    )}
    <input
      className={classNames(
        "rounded-md px-md h-xxxl border transition-colors w-full",
        "bg-gray-250 text-gray-50 border-gray-150 focus:outline-none focus:border-gray-100"
      )}
      placeholder={props.placeholder}
      type="text"
      name={props.name}
      ref={ref}
    />
  </div>
));

Input.displayName = "Input";

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  optional: PropTypes.bool,
  name: PropTypes.string,
  ref: PropTypes.object,
  className: PropTypes.string,
};

Input.defaultProps = {};

export default Input;
