import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { getCountries, getCountry, getTerms } from "./services";

import Home from "./components/Home";
import TermDetails from "./components/TermDetails";
import Submit from "./components/Submit";
import About from "./components/About";
import Login from "./components/Login";
import AppBar from "./components/AppBar";
import AppContext from "./components/AppContext";

const App = () => {
  const [countries, setCountries] = useState([]);
  const [terms, setTerms] = useState([]);
  const [user, setUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    (async () => setCountries(await getCountries()))();
  }, []);

  useEffect(() => {
    (async () => setTerms(await getTerms()))();
  }, []);

  const context = {
    countries: countries,
    terms: terms,
    user,
    setUser,
    loggedIn,
    setLoggedIn,
    modalOpen,
    setModalOpen,
  };

  return (
    <AppContext.Provider value={context}>
      <Router>
        <AppBar />
        <Switch>
          <Route
            path="/discord/login"
            render={() =>
              (window.location = `https://discord.com/api/oauth2/authorize?client_id=869854443422748753&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Flogin&response_type=code&scope=identify%20email`)
            }
          />
          <Route path="/login">
            <Login />
          </Route>
          <Route
            path={[
              "/about",
              "/submit",
              "/:countryName/:termName",
              "/:countryName",
              "/",
            ]}
          >
            <Route exact path="/submit">
              <Submit />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route path="/:countryName/:termName">
              <TermDetails />
            </Route>
            <Route path="/authorize">
              <div>Hey!</div>
            </Route>
            <Home />
          </Route>
        </Switch>
      </Router>
    </AppContext.Provider>
  );
};

App.propTypes = {};

App.defaultProps = {};

export default App;
