import flagData from "./flagData.js";
import find from "lodash.find";

const getEmojiFlag = (country) => {
  if (country === undefined) {
    return undefined;
  }

  if (typeof country !== "string") {
    return undefined;
  }

  const countryMatch = find(flagData, (flagCountry) => {
    if (flagCountry.name === country) {
      return true;
    }
  });
  if (countryMatch) {
    return countryMatch.emoji;
  } else {
    return undefined;
  }
};

export default getEmojiFlag;
