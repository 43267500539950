import React, { useEffect, useState, useContext } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import getEmojiFlag from "../helpers/flagemoji";

import { Callout } from "./Text";

const CountryDropdown = ({ countries, onFilterSelect, onCountrySelect }) => {
  const handleSelect = (event) => {
    console.log(event.target.value);
    event.preventDefault();
    onFilterSelect();
    onCountrySelect(event.target.value);
  };
  const countryRow = (country, index) => {
    return (
      <option value={country.name} key={country.name}>
        {getEmojiFlag(country.name)}
        {country.name}{" "}
      </option>
    );
  };

  return (
    <ul className="rounded-md overflow-hidden">
      <label
        htmlFor="Countries"
        className="bg-gray-200 block text-gray-50 px-lg py-lg w-full"
      >
        <Callout>Words by origin</Callout>
      </label>
      <select
        onChange={handleSelect}
        id="Countries"
        className="w-full block px-lg py-md text-base font-medium text-gray-50 bg-gray-250 form-select outline-none"
      >
        <option value="">🌐 Everywhere</option>
        {countries.map((country, index) => countryRow(country, index))}
      </select>
    </ul>
  );
};

CountryDropdown.propTypes = {
  countries: PropTypes.array,
  onFilterSelect: PropTypes.func,
  onCountrySelect: PropTypes.func,
};

export default CountryDropdown;
