import React from "react";
import logo from "../assets/logo.svg";
import Button from "./Button";
import Twitter from "../assets/twitter.js";
import Discord from "../assets/discord.js";

const AppBar = () => (
  <div className="z-10 absolute top-0 inset-x-0 flex flex-row justify-between content-center pt-xxl px-xxl">
    <div>
      <img src={logo} className="lg:hidden" alt="logo" />
    </div>
    <div className="flex">
      {/* <Button className="mr-md" to={`/about`}>
        About
      </Button> */}
      <Button className="mr-md" ghost to={`/submit`}>
        Submit
      </Button>
      <Button
        primary
        target="_blank"
        href={`https://twitter.com/beyondxlation`}
        className="flex place-items-center text-gray-300 "
      >
        <div className="mr-xsm mb-xsm">
          <Twitter width={24} />
        </div>
        <span className="whitespace-nowrap">Follow us</span>
      </Button>
      {/* <Button
        href="https://discord.gg/Pzz9NPg6"
        target="_blank"
        className="font-semibold inline-flex place-items-center justify-center w-full ml-md"
        style={{ backgroundColor: "#5969EA" }}
      >
        <div className="mr-sm mb-xsm">
          <Discord className="text-gray-50" width="16" />
        </div>
        Discord
      </Button> */}
    </div>
  </div>
);

export default AppBar;
