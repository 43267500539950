import React, { useState } from "react";
import { suggestEdit } from "../services";
import { useForm } from "react-hook-form";

const SuggestEdit = (term) => {
  const { register, handleSubmit } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const onSubmit = (data) => {
    setSubmitted(true);
    suggestEdit(data);
  };

  return (
    <div>
      {!submitted ? (
        <form className="w-full mt-lg" onSubmit={handleSubmit(onSubmit)}>
          <div className="text-gray-50 mb-md w-full">
            Any mistakes, missing ideas, context?
          </div>
          <input
            ref={register}
            type="hidden"
            name="Term"
            value={term.term.id}
          />
          <textarea
            className="h-huge w-full rounded-md p-md border transition-colors bg-gray-250 mb-xxl border-gray-150 focus:outline-none focus:border-gray-100"
            name="Edit"
            placeholder=""
            ref={register}
            style={{ resize: "none" }}
          />
          <input
            className="rounded-md w-full bg-salmon-400 hover:bg-salmon-200 cursor-pointer transition-colors text-base font-semibold py-md text-gray-250 "
            type="submit"
            value="Suggest edit"
          />
        </form>
      ) : (
        <div className="w-full bg-gray-400 flex justify-center rounded-md text-gray-50 pt-xl">
          Submitted. Thank you for your help.
        </div>
      )}
    </div>
  );
};

SuggestEdit.propTypes = {};

export default SuggestEdit;
