import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import delay from "lodash.delay";
import Modal from "./Modal";
import cross from "../assets/cross.svg";
import { Header2 } from "./Text";
import Input from "./Input";

import { submitTerm } from "../services";
import { useForm } from "react-hook-form";

const Submit = () => {
  const history = useHistory();
  const { register, handleSubmit, watch, errors } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const onSubmit = (data) => {
    setSubmitted(true);
    submitTerm(data);
  };

  const delayClose = () => delay(onClose, 10);
  const onClose = () => {
    history.push("/");
    document.body.style.overflow = "unset";
  };

  return (
    <Modal open={true} onClose={delayClose}>
      <div className="bg-gray-300 max-h-full  overflow-scroll h-screen text-gray-50 absolute w-full lg:w-3/4 xl:w-3/6  ">
        <div className="sticky bg-gradient-to-b from-gray-400 h-huger px-lg lg:px-xl w-full flex place-items-center justify-between">
          <div></div>
          <div
            onClick={() => delayClose()}
            className="hover:bg-gray-250 hover:bg-opacity-20 transition-colors p-sm cursor-pointer flex place-items-center rounded"
          >
            <img src={cross} />
          </div>
        </div>
        <div className="px-xl lg:px-huger pb-huge">
          {submitted ? (
            <>
              <Header2 className="mb-lg">Thank you for your submission</Header2>
              <p className="font-serif base">
                We&apos;ll review it as soon as possible.
              </p>
            </>
          ) : (
            <>
              <Header2 className="mb-lg">Submit a word</Header2>
              <p className="font-normal font-serif text-base mb-xxl">
                Please tell us as much as you can about the word and its
                cultural context.
              </p>
              <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
                <div className="flex flex-col lg:flex-row">
                  <Input
                    label="Word"
                    name="term"
                    ref={register}
                    className="lg:mr-lg lg:w-2/4 mb-md"
                  />
                  <Input
                    label="Language"
                    name="origin"
                    ref={register}
                    className="lg:w-2/4 mb-md"
                  />
                </div>
                <label
                  className="font-normal text-gray-75 text-base mt-md mb-sm"
                  htmlFor="meaning"
                >
                  Meaning and description
                </label>
                <textarea
                  className="h-massive rounded-md p-md border transition-colors bg-gray-250 mb-xxl border-gray-150 focus:outline-none focus:border-gray-100"
                  name="meaning"
                  placeholder="Detail, context and examples help to give readers a chance to get close to the full idea, as used by natives."
                  style={{ resize: "none" }}
                  ref={register}
                />
                <div className="flex flex-col lg:flex-row">
                  <Input
                    label={`Your name`}
                    optional
                    name="contributorName"
                    ref={register}
                    className="mr-lg mb-md lg:w-2/4"
                  />
                  <Input
                    label="Your email"
                    optional
                    name="contributorEmail"
                    className="lg:w-2/4 mb-md"
                    ref={register}
                  />
                </div>
                <input
                  className="rounded-md bg-salmon-400 hover:bg-salmon-200 cursor-pointer transition-colors text-base font-semibold py-md text-gray-250 mt-md"
                  type="submit"
                  value="Submit"
                />
              </form>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

Submit.propTypes = {};

export default Submit;
