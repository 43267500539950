import React from "react";
import PropTypes from "prop-types";

function makeTextComponent(name, className, component, ...props) {
  const TextComponent = ({ children, ...componentProps }) => {
    return React.createElement(
      component,
      { className: className + " " + componentProps.className },
      children
    );
  };

  TextComponent.propTypes = {
    children: PropTypes.string,
    className: PropTypes.string,
    component: PropTypes.object,
  };
  TextComponent.displayName = name;
  return TextComponent;
}
export const Header2 = makeTextComponent(
  "Header2",
  "text-lg text-gray-50 font-semibold font-sans",
  "h2"
);

export const Header3 = makeTextComponent(
  "Header3",
  "text-gray-75 text-base font-semibold leading-small font-sans",
  "h3"
);

export const Callout = makeTextComponent(
  "Callout",
  "font-semibold text-xsm tracking-widest uppercase",
  "span"
);

export const Link = makeTextComponent(
  "Link",
  "flexbox rounded transition place-items-center justify-center px-md cursor-pointer py-sm hover:bg-gray-300 mr-lg",
  "a"
);

export default {
  Callout,
  Header2,
  Link,
};
