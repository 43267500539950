import base from "./airtable";

const formatCountry = (country) => {
  let flag = country.get("Flag");
  flag = flag && flag[0] ? flag[0] : null;

  return {
    id: country.id,
    name: country.get("Name"),
    flag,
    lat: country.get("Latitude"),
    lng: country.get("Longitude"),
    termCount: country.get("TermCount"),
    featured: country.get("Featured"),
    termIds: country.get("Terms") || [],
  };
};

export const getCountries = async () => {
  try {
    const countries = await base("Countries")
      .select({
        maxRecords: 100,
        view: "Main",
        sort: [{ field: "TermCount", direction: "desc" }],
      })
      .firstPage();

    return countries.map(formatCountry);
  } catch (err) {
    console.log(err);
  }
};

export const getCountry = async (id) => {
  try {
    const country = await base("Countries").find(id);

    return formatCountry(country);
  } catch (err) {
    console.log(err);
  }
};
