import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import getEmojiFlag from "../helpers/flagemoji";

import LongArrow from "../assets/arrow-long.svg";
import Speaker from "../assets/speaker.svg";

import Spinner from "./Spinner";
import { Header2, Callout } from "./Text";

const TermCard = ({ term, className, nocover }) => {
  return (
    <div className="w-full relative">
      <Link
        to={`/${term.country}/${term.name}`}
        key={term.id}
        className={
          "block transition-colors bg-gray-200 hover:bg-gray-250 w-full rounded-md overflow-hidden pb-xl" +
          " " +
          className
        }
      >
        <div className="flex flex-col h-full place-content-between">
          <div>
            {term.cover && !nocover && (
              <div
                style={{
                  backgroundImage: `url(${term.cover})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  height: 240,
                  width: "100%",
                }}
              />
            )}
            <div className="pt-xl px-xl">
              <div className="flex place-items-center text-gray-50">
                <span className="mr-sm text-md">
                  {getEmojiFlag(term.country)}
                </span>
                <Callout>{term.country}</Callout>
              </div>

              <Header2 className="mt-sm">
                {term.name}
                <span className="text-gray-100 font-normal text-md">
                  &nbsp;{!!term.native && `(${term.native})`}
                </span>
              </Header2>
              <div
                style={{ maxWidth: 680 }}
                className="font-normal font-serif text-sm text-gray-50 mt-sm whitespace-pre-wrap"
              >
                {term.intro ? term.intro : term.meaning}
              </div>
            </div>
          </div>
          {term.intro && (
            <div>
              <div className="text-gray-100 font-normal flex mt-lg justify-items-center px-xl">
                <span className="mr-sm">Read more</span>
                <img src={LongArrow} />
              </div>
            </div>
          )}
        </div>
      </Link>{" "}
      <a
        target="_blank"
        className="p-sm hover:bg-gray-250 transition-colors opacity-70 hover:opacity-100 absolute z-20 right-0 top-0 mr-xl mt-xl rounded"
        rel="noreferrer"
        style={{ top: term.intro && !nocover ? 240 : 0 }}
        href={`https://forvo.com/search/${
          !!term.native ? term.native : term.name
        }`}
      >
        <img src={Speaker} />
      </a>
    </div>
  );
};

TermCard.propTypes = {
  term: PropTypes.object,
  className: PropTypes.string,
  nocover: PropTypes.bool,
};

export default TermCard;
