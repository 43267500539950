import React from "react";
const initialState = {
  loggedIn: false,
  user: {},
  countries: [],
  terms: [],
};
const AppContext = React.createContext(initialState);

export default AppContext;
