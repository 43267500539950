import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

import WorldMap from "./WorldMap";
import CardList from "./CardList";
import AppContext from "./AppContext";
import ContributorList from "./ContributorList";
import FeaturedTerms from "./FeaturedTerms";
import { Link } from "react-router-dom";
// import Discord from "../assets/discord.js";
// import Subscribe from "./Subscribe";
// import Button from "./Button";
// import { getCountry } from "../services";
import { Header2 } from "./Text";

import logo from "../assets/logo.svg";
import cross from "../assets/cross.svg";

import useTitle from "../hooks/useTitle";
import { WEBGL } from "../helpers/WebGL.js";
import CountryList from "./CountryList";
import CountryDropdown from "./CountryDropdown";

const Home = () => {
  let history = useHistory();
  const { countryName, termName } = useParams();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isFiltered, setFiltered] = useState(false);
  const [title, setTitle] = useTitle();

  const context = useContext(AppContext);
  const terms = context.terms;
  const countries = context.countries;
  const featuredTerms = terms.filter((term) => {
    return term.featured;
  });
  const remainingTerms = terms.filter((term) => {
    return !term.featured;
  });

  let filteredTerms = terms;

  if (countryName) {
    filteredTerms = terms.filter((term) => term.country === countryName);
  }

  useEffect(async () => {
    if (countryName && countries.length) {
      // is it featured?
      const featuredCountry = countries.filter(
        (country) => country.name === countryName && country.featured
      )[0];
      setSelectedCountry(featuredCountry || null);
      setTitle(countryName);
    } else {
      setSelectedCountry(null);
      setTitle();
    }
  }, [countries, countryName]);

  // check if back button pressed
  useEffect(() => {
    window.onpopstate = (e) => {
      document.body.style.overflow = termName ? "hidden" : "unset";
    };
  });

  const onCountrySelect = (country) => {
    const path = country ? `/${country}` : "/";
    history.push(path);
  };
  const listRef = useRef();

  // jump to top of list
  const onFilterSelect = () => {
    setFiltered(true);
    listRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    // grid
    <div className="flex place-items-center p-lg flex-col w-screen h-screen ">
      {/* first grid block 6-2 */}
      <div className="mt-hugest pb-huge lg:mt-massive grid grid-cols-12 gap-xxl max-w-screen-xl">
        <div className="lg:col-span-7 col-span-12">
          <div className="flex flex-col justify-center content-center mt-huge lg:-mt-lg">
            <img
              src={logo}
              className="hidden lg:block w-xxl h-xxl mb-xxl ml-sm"
              alt="logo"
            />
            <h1 className="text-xl lg:text-xxl text-center lg:text-left leading-none tracking-tight font-semibold">
              <span className="text-gray-50">Beyond</span>
              <br />
              <span style={{ marginLeft: 6 }} className="text-salmon-400">
                Translation
              </span>
              <br />
            </h1>
            <p className=" text-center lg:text-left ml-sm mt-sm text-gray-50 text-md">
              The world&apos;s least translatable words.
            </p>
          </div>
        </div>

        <div className="lg:col-span-5 hidden lg:flex col-span-12  justify-center">
          {WEBGL.isWebGLAvailable() && (
            <WorldMap
              width={480}
              height={480}
              countries={countries}
              selectedCountry={selectedCountry}
              onCountrySelect={onCountrySelect}
            />
          )}
        </div>
        <div className="col-span-12 mt-huge">
          <FeaturedTerms terms={featuredTerms} />
        </div>
        <div className="col-span-12 lg:col-span-4 hidden lg:block lg:mt-hugest">
          <CountryList onFilterSelect={onFilterSelect} countries={countries} />
          {/* <ContributorList /> */}
        </div>
        <div className="col-span-12 lg:col-span-4 block lg:hidden lg:mt-hugest">
          <CountryDropdown
            onFilterSelect={onFilterSelect}
            onCountrySelect={onCountrySelect}
            countries={countries}
          />
          {/* <ContributorList /> */}
        </div>
        <div className="col-span-12 lg:col-span-8  lg:my-huge">
          <div ref={listRef} className="col-span-12 mb-lg">
            <div className="flex justify-between">
              <Header2 className="">
                {countryName && isFiltered ? (
                  <div>
                    Words from{" "}
                    <span className="text-salmon-400">{countryName}</span>
                  </div>
                ) : (
                  "Recent words"
                )}
              </Header2>
              {countryName && (
                <Link
                  style={{ marginTop: 10 }}
                  onClick={() => setFiltered(false)}
                  className="ml-lg text-base font-semibold font-sans text-salmon-400"
                  to="/"
                >
                  &nbsp;All&nbsp;languages
                </Link>
              )}
            </div>
          </div>
          <CardList
            terms={countryName && isFiltered ? filteredTerms : remainingTerms}
          />
        </div>
        {/* <div className="col-span-12 lg:col-span-2"></div> */}
      </div>

      {/* second block 3-1  */}
    </div>
  );
};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
