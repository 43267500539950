import React, { useEffect, useState, useContext } from "react";
import { createPortal } from "react-dom";
import { Transition } from "@headlessui/react";
import AppContext from "./AppContext";

const Modal = ({ open, onClose, children }) => {
  // const [show, setShow] = useState(false);
  const { modalOpen, setModalOpen } = useContext(AppContext);
  // const [terms, setTerms] = useState([]);
  // useEffect(() => {
  //   (async () => setTerms(await getTerms(country.termIds)))();
  // }, [country]);
  useEffect(() => setModalOpen(open), [open]);

  useEffect(() => {
    modalOpen && (document.body.style.overflow = "hidden");
    !modalOpen && (document.body.style.overflow = "unset");
  }, [modalOpen]);

  const delayClose = () => {
    setModalOpen(false);
    onClose();
  };

  const content = !!open && (
    <div className="fixed z-20 inset-0">
      <Transition
        show={modalOpen}
        enter="transition-opacity ease-in duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="absolute inset-0 bg-gray-200 bg-opacity-80 cursor-pointer"
          onClick={delayClose}
        />
      </Transition>
      {children}
    </div>
  );

  return createPortal(content, document.getElementById("modal_root"));
};

export default Modal;
