import React, { useEffect, useState, useContext } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import getEmojiFlag from "../helpers/flagemoji";

import { Callout } from "./Text";

const CountryList = ({ countries, onFilterSelect }) => {
  const countryRow = (country, index) => {
    return (
      <Link onClick={() => onFilterSelect()} to={`/${country.name}`}>
        <li
          key={country.name}
          className={classNames(
            "flex px-lg py-md text-base font-medium text-gray-50 bg-gray-200 hover:bg-gray-250 transition-color",
            index % 2 === 0 && "bg-gray-225"
          )}
        >
          <div className="flex justify-between w-full">
            <div className="flex place-items-center">
              <span key={country.name} className="ml-xsm mr-sm">
                {getEmojiFlag(country.name)}
              </span>
              {country.name}{" "}
            </div>
            <span className="text-gray-150 ml-sm">{country.termCount}</span>
          </div>
        </li>
      </Link>
    );
  };

  return (
    <ul className="rounded-md overflow-hidden">
      <li className="bg-gray-200 text-gray-50 px-lg py-lg">
        <Callout>Words by origin</Callout>
      </li>
      <Link to={`/`}>
        <li className="flex px-lg py-md text-base font-medium text-gray-50 bg-gray-200 hover:bg-gray-250 transition-color">
          <span className="ml-xsm mr-sm">🌐</span> Everywhere
        </li>
      </Link>
      {countries.map((country, index) => countryRow(country, index))}
    </ul>
  );
};

CountryList.propTypes = {
  countries: PropTypes.array,
  onFilterSelect: PropTypes.func,
};

export default CountryList;
