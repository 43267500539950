import React from "react";
import PropTypes from "prop-types";

import TermCard from "./TermCard";
import { Header2 } from "./Text";
import Button from "./Button";
import Spinner from "./Spinner";

const CardList = ({ terms }) => {
  const termsLoaded = terms.length;
  return (
    <div>
      {termsLoaded ? (
        terms.map((term) => (
          <TermCard key={term.name} term={term} nocover className="mb-xxl" />
        ))
      ) : (
        <div className="flex bg-gray-200 hover:bg-gray-250 text-gray-100 w-full  place-items-center justify-center rounded-md p-xl">
          <Spinner color="#E9E7E7" />
          <div className="ml-md">Loading terms</div>
        </div>
      )}
      <div className="flex flex-col lg:flex-row bg-gray-200 text-gray-100 w-full  place-items-center justify-center rounded-md p-xl">
        <div className="mr-md font-normal text-base font-sans">
          Have a word in mind?
        </div>{" "}
        <Button className="mt-md lg:mt-auto" primary to="/Submit">
          Add your entry
        </Button>
      </div>
    </div>
  );
};

CardList.propTypes = {
  terms: PropTypes.array,
};

export default CardList;
